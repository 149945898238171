import {getCurrentInstance, reactive, toRefs, onBeforeMount, onMounted, defineComponent, nextTick, computed} from 'vue';
import TestContentUtil ,{ITestContentDataObj} from "./testContentUtil";
import ContentEngine from "@/components/base_comp/contentEngine/ContentEngine.vue";
export default defineComponent ({
    name: "testContent",
    components: {ContentEngine},
    setup(props,context){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:ITestContentDataObj=reactive<ITestContentDataObj>({
            utilInst:{} as any,
            testContentParams:{}
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new TestContentUtil(proxy,dataObj);
        })
        onMounted(()=>{

        })
        return{
            ...toRefs(dataObj)
        }
    }
});